import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CustomNavbar from './Navbar'; // Import the CustomNavbar component
import { Helmet } from 'react-helmet'; // Import Helmet
import './styles.css';
import DynamicBackground from './DynamicBackground';
const LearnMore = () => {
  return ( <>      <DynamicBackground />
    <div className="main-container">
        <Helmet>
        <title>Nicholas Skeba - Learn More</title>
        <meta
          name="description"
          content="Discover Nicholas Skeba's professional portfolio showcasing expertise in PHP, Python, JS, and AI-focused projects. Explore web development, mobile apps, and more."
        />
      </Helmet>
      <Container fluid className="styled-container text-black padding15 border border-black border-5">
        <CustomNavbar /> {/* Add the CustomNavbar here */}
        
        <div fluid className="text-black padding15 border border-black border-3 esume-container-style  StyledContainer">
          <h1 className="learn-more-h1-style">NICHOLAS SKEBA</h1>
          <Row className="align-items-center mb-4">
            <Col md={3}>
              <img  className="round-image-style" style={{ width: 140, borderRadius: 100 }} src="path-to-your-image/profile.png" alt="Nicholas Skeba"/>
            </Col>
            <Col md={9}>
              <p className="p-styled">
                Born and raised in Grand Rapids, Michigan, I am a dynamic professional with a unique blend of skills and interests, particularly in the fields of marketing and program management. My MBA from ESMT Berlin has provided me with a solid foundation in business principles and practices, enabling me to excel in diverse professional environments.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <p className="p-styled">nicholas.skeba@mba2019.esmt.org • <a href="https://www.linkedin.com/in/nicholas-skeba/" target="_blank" rel="noopener noreferrer">linkedin.com/in/nicholas-skeba</a> • <a href="https://skeba.info" target="_blank" rel="noopener noreferrer">https://skeba.info</a></p>
              
              
              
              <h2 className="h2-styled">EXPERIENCE</h2>
                   <h3 className="h3-styled">Global Product Marketing Manager</h3>
                <p className="p-styled">Fluke Corporation | Seattle, WA | Nov 2023 – Present</p>
                <ol className="ol-style">
                  <li>
                    Uncovered customer and competitive insights to drive marketing actions and delivered findings cross-functionally.
                  </li>
                  <li>
                    Partnered with product managers to develop product roadmaps and customer buying journeys.
                  </li>
                  <li>
                    Created strategic marketing plans for new product introductions (NPIs) and compelling core product messaging.
                  </li>
                  <li>
                    Coordinated with commercial strategy partners across EMEA, APAC, and the Americas to determine marketing priorities.
                  </li>
                  <li>
                    Acted as the product line and customer expert within marketing to align strategies across teams.
                  </li>
                </ol>

                <h3 className="h3-styled">Marketing Manager</h3>
                <p className="p-styled">Ossia Inc. | Seattle, WA | 2022 – 2023</p>
                <ol className="ol-style">
                  <li>
                    Prepared the startup for the Consumer Electronics Show by creating marketing materials, website design, and implementing HubSpot.
                  </li>
                  <li>
                    Generated over 300 high-quality leads through in-person sales and follow-up campaigns.
                  </li>
                  <li>
                    Successfully completed a 3-month contract and contributed to the Ossia Cota Power Table project, recognized as one of the "200 Best Inventions of 2022."
                  </li>
                </ol>

           
                <h3 className="h3-styled">Lifecycle Marketing Manager</h3>
                <p className="p-styled">Parallels GmbH | Seattle, WA | Aug 2020 – Current</p>
             <ol className="ol-style">
                  <li>Developed and exceeded marketing channel goals by over 10% through targeted customer segmentation.</li>
                  <li>A/B tested campaigns and improved in-product messaging, email, and website layouts in order to improve overall product acquisition that contributed to 2020 Q4 sales goals of $2 million over the targeted revenue.</li>
                  <li>Drove new customer acquisition through creation of customer trial survey campaign that added additional $30k in sales within 2 months of launch.</li>
                  <li>Devised campaign with 10%+ CTR in converting customers from perpetual to SaaS subscription, increasing LTV 20%.</li>
                  <li>Managed localization, creative, and marketing teams to achieve 2-week targeted implementation timeline.</li>
              </ol>
        
              
           
                <h3 className="h3-styled">Customer Acquisition and Retention Internship</h3>
                <p className="p-styled">DiscoEat GmbH | Berlin, Germany | Oct 2019 – Dec 2019</p>
        <ol className="ol-style">
                  <li>Led a five-member team, coordinating tasks within Asana to analyze syndicated customer data, perfecting the customer journey from sign up to referral, which led to 30% referral increase after retooling end to end referral process.</li>
                  <li>Spearheaded weekly strategy meetings, discussing product and brand ambassador campaign to executive stakeholders (CEO, marketing lead), defining referral strategy based on positioning, pricing, and operation cost.</li>
                  <li>Utilized data analytics to extract insights and optimize customer acquisition strategy with the effect of improving ROI.</li>
           </ol>
            
              
           
                <h3 className="h3-styled">SaaS Performance Marketing and Growth Strategy</h3>
                <p className="p-styled">CostBrain Llc | Chicago, IL | Sept 2015 – Dec 2018</p>
               <ol className="ol-style">
                  <li>Increased organic traffic +200% by conducting a complete technical content SEO audit and content strategy.</li>
                  <li>Scaled paid search campaigns, maximizing ROAS by maintaining acquisition efficiency.</li>
                  <li>Interviewed 50 potential users, analyzing results with team to define target audience for SaaS product, defining optimal channels to understand competitive landscape and target persona needs.</li>
             </ol>
           
              
            
                <h3 className="h3-styled">SEM / SEO Campaign Manager</h3>
                <p className="p-styled">Skeba Digital Corp | Chicago, IL | Sep 2008 – Dec 2018</p>
             <ol className="ol-style">
                  <li>Improved CTR 50% monitoring digital marketing assets within Google Analytics (email, web banner, social media, web page) using A/B testing to refine layouts and determine highest performing campaigns.</li>
                  <li>Onboarded, trained, and oversaw 3 copywriters to implement content strategy formulated from Analytics analysis.</li>
                  <li>Owned new customer growth, amplifying site traffic from 20k users per month to 500k users by directing team to compose content in WordPress CMS.</li>
            
             </ol>
              
              <h2 className="h2-styled">EDUCATION</h2>
           
                <h3 className="h3-styled">Master of Business Administration</h3>
                <p className="p-styled">European School of Management and Technology | Berlin, Germany | Dec 2019</p>
        
              
            
                <h3 className="h3-styled">Consumer Behavior and Theory MBA Module</h3>
                <p className="p-styled">Yale School of Management | New Haven, CT | Oct 2019</p>
          
              
         
                <h3 className="h3-styled">Bachelor of Business Administration</h3>
                <p className="p-styled">Western Michigan University | Kalamazoo, MI | May 2007</p>
         
              
              <h2 className="h2-styled">SKILLS</h2>
        
                <p className="p-styled"><strong>Languages:</strong> English: Native • Spanish: Advanced (B2) • German: Advanced (B2)</p>
                <p className="p-styled"><strong>Design:</strong> HTML • CSS • JavaScript • Adobe Creative Suite (Photoshop, Illustrator)</p>
                <p className="p-styled"><strong>Marketing:</strong> SEO • CRM • Ahrefs • SEM • Ad Platforms (Facebook, Bing, Twitter, Google AdWords)</p>
                <p className="p-styled"><strong>Tools:</strong> Jira • Asana • Microsoft Office (PowerPoint, Word, Excel, Teams) • Google Webmaster • SQL</p>



            </Col>
          </Row>
        </div>
      </Container>
    </div></>
  );
};


export default LearnMore;
