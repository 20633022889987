import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomNavbar from './Navbar'; // Import the CustomNavbar
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Helmet } from 'react-helmet'; // Import Helmet
import DynamicBackground from './DynamicBackground';

const MainPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  const [showWebPopup, setShowWebPopup] = useState(false);
  const [latestArticles, setLatestArticles] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    const fetchMediumArticles = async () => {
      try {
        const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@nika.skeba');
        const data = await response.json();
        const articles = data.items.slice(0, 3).map(item => {
          // Extract the image URL from the description
          const regex = /<img.*?src="(.*?)"/;
          const match = regex.exec(item.description);
          const thumbnail = match ? match[1] : 'default-image-url.jpg'; // Use a default image if none is found

          return {
            title: item.title,
            link: item.link,
            thumbnail: thumbnail,
          };
        });
        setLatestArticles(articles);
      } catch (error) {
        console.error("Failed to fetch Medium articles", error);
      }
    };

    fetchMediumArticles();

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleShowMobilePopup = () => {
    setShowMobilePopup(true);
  };

  const handleCloseMobilePopup = () => {
    setShowMobilePopup(false);
  };

  const handleShowWebPopup = () => {
    setShowWebPopup(true);
  };

  const handleCloseWebPopup = () => {
    setShowWebPopup(false);
  };

  return (
    <>      <DynamicBackground />
    <div className="main-container">
          <Helmet>
        <title>Nicholas Skeba - AI Developer & Marketing Specialist</title>
        <meta
          name="description"
          content="Discover Nicholas Skeba's professional portfolio showcasing expertise in PHP, Python, JS, and AI-focused projects. Explore web development, mobile apps, and more."
        />
      </Helmet>

      <Container fluid className="styled-container padding15 text-white border border-black border-5">
        <CustomNavbar 
          handleShowWebPopup={handleShowWebPopup} 
          handleShowMobilePopup={handleShowMobilePopup} 
          isMobile={isMobile} 
          isDropdownOpen={isDropdownOpen} 
          toggleDropdown={toggleDropdown} 
          closeDropdown={closeDropdown} 
          dropdownRef={dropdownRef} 
        />
        
        <Row className="align-items-center top-text-image- text-end">
          <Col md={5}>
            <h1 className="styled-h1">
              NICHOLAS <br /> SKEBA
            </h1>
            <p style={{ color: '#262626'}}>PHP / Python / JS <br /> AI Focused</p>
            <div className="button-container">
              <Link to="/learn-more">
                <button className="styled-button">
                  <span className="left"></span>
                  <span className="right">Learn More</span>
                </button>
              </Link>
            </div>
          </Col>
          <Col md={7} style={{ position: 'relative' }}>
            <img src="path-to-your-image/drawing-main.png" alt="Main" className="img-fluid image-space" />
       
          </Col>
        </Row>

        <Row>
          <Col md={4} ><Link to="/portfolio">
            <Card className="styled-image-card text-white"> 
              <div className="image-container" style={{ backgroundImage: 'url(path-to-your-image/image1.png)' }}></div>
              <Card.Body className="card-content">
               
                  <button className="styled-button">
                    <span className="left"></span>
                    <span className="right">Web Dev</span>
                  </button>
               
              </Card.Body>
            </Card> </Link>
          </Col>
          <Col md={4} className="mb-3">    <Link to="/portfolio">

            <Card className="styled-image-card text-white">
              <div className="image-container" style={{ backgroundImage: 'url(path-to-your-image/image2.png)' }}></div>
              <Card.Body className="card-content">
           
     
                  <button className="styled-button">
                    <span className="left"></span>
                    <span className="right">Mobile Apps</span>
                  </button>
        
              </Card.Body>
            </Card>        </Link>
          </Col>
          <Col md={4} className="mb-3"><Link to="/media">
            <Card className="styled-image-card text-white">
              <div className="image-container" style={{ backgroundImage: 'url(path-to-your-image/image3.png)' }}></div>
              <Card.Body className="card-content">
               
                
                  <button className="styled-button">
                    <span className="left"></span>
                    <span className="right">Media</span>
                  </button>
                
              </Card.Body>
            </Card></Link>
          </Col>
        </Row>

        <Row className="align-items-center" >
          <Col md={12}>
            <h2 className="h2-styled">Professional Summary</h2>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col md={3}>
            <img src="path-to-your-image/profilephoto.png" alt="Profile" className="img-fluid profile-image-sizing" />
          </Col>
          <Col md={9}>
            <p className="professional-summary-text">
              In my professional journey, I have honed my expertise in client journey optimization, data-driven strategies, and cross-functional collaboration, making me a specialist in Lifecycle Marketing Management. I am skilled in conducting A/B testing, creating insightful dashboards, and utilizing a range of software tools to drive marketing growth. My software skills are extensive, including Salesforce Marketing Cloud, Marketo, Google Analytics, Tableau, Optimizely, and Mailchimp.
            </p>
            <p className="professional-summary-text">
              I also possess a strong foundation in coding, with proficiency in Python, HTML, CSS, JavaScript, PHP, Large Language Models (LLM), and SQL. This combination of marketing savvy and technical expertise significantly enhances my capabilities. Additionally, I am multilingual, with advanced proficiency in German and Spanish and native fluency in English, which has been invaluable in my international business dealings.
            </p>
          </Col>
        </Row>

        {/* Medium RSS Feed Section */}
        <Card className="styled-card text-white mt-4">
          <Card.Body>
            <h2 className="h2-styled">Latest Articles</h2>
            <Row>
              {latestArticles.map((article, index) => (
                <Col md={4} key={index}>
                  <div className="medium-article-wrapper">
                    <a href={article.link} target="_blank" rel="noopener noreferrer">
                      <img 
                        src={article.thumbnail} 
                        alt={article.title} 
                        style={{ objectFit: 'cover' }} 
                        className="img-fluid" 
                      />
                    </a>
                    <p className="professional-summary-text">{article.title}</p>
                  </div>
                </Col>
              ))}
            </Row>
            <div className="">
              <a href="https://medium.com/@nika.skeba" target="_blank" rel="noopener noreferrer">
                <button className="styled-button">
                  <span className="left"></span>
                  <span className="right">View More</span>
                </button>
              </a>
            </div>
          </Card.Body>
        </Card>
      </Container>


    </div></>
  );
};



export default MainPage;
