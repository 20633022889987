import React, { useEffect, useRef } from 'react';

const DynamicBackground = () => {
  const canvasRef = useRef(null);
  const gap = 30; // Gap to control spacing
  const shiftSpeed = 0.001; // Speed of the grid shifting in one direction

  // Random coding symbols to display
  const codingSymbols = [
    "{", "}", "[", "]", "(", ")", "<", ">", ";", ":", "=", "+", "-", "*", "/", "&", "|", "^", "%",
    "!", "?", "#", "$", "@", "~", "`", ".", ",", "\"", "'", "\\", "let", "const", "function",
    "return", "if", "else", "=>", "new", "try", "catch", "while", "for"
  ];

  const getRandomSymbol = () =>
    codingSymbols[Math.floor(Math.random() * codingSymbols.length)];

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let shiftX = 0;
    let shiftY = 0;
    let symbols = []; // Dynamic symbols
    const staticSymbols = []; // Static pre-generated symbols

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight - 50; // Adjust for navbar
      generateStaticSymbols(); // Recreate static symbols on resize
      draw(); // Redraw canvas
    };

    const generateStaticSymbols = () => {
      staticSymbols.length = 0; // Clear existing static symbols
      const rows = Math.ceil(canvas.height / gap);
      const cols = Math.ceil(canvas.width / gap);

      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
          if (Math.random() < 0.4) { // 40% chance to generate a static symbol
            staticSymbols.push({
              x: col * gap + gap / 2,
              y: row * gap + gap / 2,
              symbol: getRandomSymbol(),
              size: Math.random() * 6 + 10, // Size between 10px to 16px
            });
          }
        }
      }
    };

    const createDynamicSymbol = (x, y) => ({
      x,
      y,
      symbol: getRandomSymbol(),
      size: Math.random() * 6 + 10, // Size between 10px to 16px
      opacity: 0, // Start invisible
      lifetime: Math.random() * 2000 + 2000, // Lifetime between 2s to 4s
      creationTime: Date.now(),
    });

    const drawStaticSymbols = () => {
      staticSymbols.forEach((symbol) => {
        ctx.font = `${symbol.size}px Monospace`;
        ctx.fillStyle = `rgba(0, 0, 0, 0.8)`; // Slightly faded black
        ctx.fillText(symbol.symbol, symbol.x, symbol.y);
      });
    };

    const drawDynamicSymbols = () => {
      symbols = symbols.filter((symbol) => {
        const age = Date.now() - symbol.creationTime;
        if (age > symbol.lifetime) return false; // Remove symbols past their lifetime

        // Update opacity to fade in and out
        const midLife = symbol.lifetime / 2;
        if (age < midLife) {
          symbol.opacity = age / midLife; // Fade in
        } else {
          symbol.opacity = 1 - (age - midLife) / midLife; // Fade out
        }

        // Draw dynamic symbol
        ctx.font = `${symbol.size}px Monospace`;
        ctx.fillStyle = `rgba(0, 0, 0, ${symbol.opacity})`;
        ctx.fillText(symbol.symbol, symbol.x + shiftX, symbol.y + shiftY);

        return true; // Keep the symbol
      });
    };

    const shiftSymbols = () => {
      shiftX += shiftSpeed;
      shiftY += shiftSpeed;

      // Loop back to start to maintain grid within screen bounds
      if (shiftX >= gap) shiftX = 0;
      if (shiftY >= gap) shiftY = 0;
    };

    const randomizeSymbols = () => {
      const rows = Math.ceil(canvas.height / gap);
      const cols = Math.ceil(canvas.width / gap);

      // Randomly add new symbols at vacant positions
      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
          if (Math.random() < 0.05) { // 5% chance to create a new symbol
            symbols.push(createDynamicSymbol(col * gap + gap / 2, row * gap + gap / 2));
          }
        }
      }
    };

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas
      drawStaticSymbols(); // Draw static symbols
      drawDynamicSymbols(); // Draw dynamic symbols
    };

    const animate = () => {
      draw();
      shiftSymbols();
      requestAnimationFrame(animate);
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas(); // Initial setup

    animate();

    const randomizeInterval = setInterval(randomizeSymbols, 500); // Randomize symbols every 500ms

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      clearInterval(randomizeInterval); // Cleanup
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="canvas-background"
      style={{
        backgroundColor: 'white',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: -1,
      }}
    />
  );
};

export default DynamicBackground;