import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import CustomNavbar from './Navbar'; // Import the CustomNavbar
import SoundCloudWidgetPlayer from './SoundCloudWidgetPlayer'; // Import SoundCloudWidgetPlayer
import './styles.css';
import { Helmet } from 'react-helmet'; // Import Helmet

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import DynamicBackground from './DynamicBackground';
const Media = () => {
  return ( <>      <DynamicBackground />
    <div className="main-container">
              <Helmet>
        <title>Nicholas Skeba - Media</title>
        <meta
          name="description"
          content="Discover Nicholas Skeba's professional portfolio showcasing expertise in PHP, Python, JS, and AI-focused projects. Explore web development, mobile apps, and more."
        />
      </Helmet>
      <Container fluid className="styled-container text-white padding15 border border-black border-5 ">
        <CustomNavbar />
        
        {/* Music Production Section */}
        <Card className="styled-card text-white mt-4" style={{ border: '10px solid white' }}>
          <Card.Body>
            <Row className="align-items-center">
              <Col md={6}>
                <h2 className="h2-styled">Music Production</h2>
                <p className="p-styled">Music experimentation using Ableton</p>
                <a href="https://soundcloud.com/datenflux" target="_blank" rel="noopener noreferrer">
                  <button className="styled-button">
                    <span className="left"></span>
                    <span className="right">SoundCloud</span>
                  </button>
                </a>
              </Col>
              <Col md={6}>
                <div className="soundcloud-player-wrapper">
                  <SoundCloudWidgetPlayer
                    url="https://soundcloud.com/datenflux"
                    options={{
                      auto_play: false,
                      hide_related: false,
                      show_comments: true,
                      show_user: true,
                      show_reposts: false,
                      visual: true,
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* Latest Videos Section - Four Image Gallery with YouTube link */}
        <Card className="styled-card text-white mt-4" style={{ border: '10px solid white' }}>
          <Card.Body>
            <h2 className="h2-styled">Latest Videos</h2>
            <Row>
              {/* Thumbnail 1 */}
              <Col md={3}>
                <a href="https://www.youtube.com/watch?v=7T4NDjRcfX0" target="_blank" rel="noopener noreferrer">
                  <img
                    src="path-to-your-image/Techno.png"
                    alt="Techno Video"
                    className="img-fluid project-thumbnail thumbnail-hover"
                    style={{ border: '2px solid white', borderRadius: '10px' }}
                  />
                </a>
              </Col>
              {/* Thumbnail 2 */}
              <Col md={3}>
                <a href="https://www.youtube.com/watch?v=AVGUu_DY66o&t=7s" target="_blank" rel="noopener noreferrer">
                  <img
                    src="path-to-your-image/Flight.png"
                    alt="Flight Video"
                    className="img-fluid project-thumbnail thumbnail-hover"
                    style={{ border: '2px solid white', borderRadius: '10px' }}
                  />
                </a>
              </Col>
              {/* Thumbnail 3 */}
              <Col md={3}>
                <a href="https://www.youtube.com/watch?v=9WHB7Wk9d-s" target="_blank" rel="noopener noreferrer">
                  <img
                    src="path-to-your-image/aitoday.png"
                    alt="AI Today Video"
                    className="img-fluid project-thumbnail thumbnail-hover"
                    style={{ border: '2px solid white', borderRadius: '10px' }}
                  />
                </a>
              </Col>
              {/* Thumbnail 4 */}
              <Col md={3}>
                <a href="https://www.youtube.com/watch?v=sc7CpsFLWK0" target="_blank" rel="noopener noreferrer">
                  <img
                    src="path-to-your-image/Robot.png"
                    alt="Robot Video"
                    className="img-fluid project-thumbnail thumbnail-hover"
                    style={{ border: '2px solid white', borderRadius: '10px' }}
                  />
                </a>
              </Col>
            </Row>

            {/* YouTube link below the gallery */}
            <div className="mt-4">
              <a href="https://www.youtube.com/@Official_AI_Zone" target="_blank" rel="noopener noreferrer">
                <button className="styled-button">
                  <span className="left"></span>
                  <span className="right">YouTube</span>
                </button>
              </a>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div></>
  );
};

export default Media;